import request from '../request'


// 获取定期活动
export function lockedList(data) {
	return request({
		url: '/earn/locked/activity/list',
		method: 'POST',
		data: data
	})
}


// // 获取定投活动
export function autoInvestList(data) {
	return request({
		url: '/earn/autoInvest/activity/list',
		method: 'POST',
		data: data
	})
}


// 通过id获取定期活动详情
export function lockedDetail(data) {
	return request({
		url: '/earn/locked/activity/Detail',
		method: 'POST',
		data: data
	})
}


// 创建定期活动订单
export function lockedAdd(data) {
	return request({
		url: '/earn/locked/add',
		method: 'POST',
		data: data
	})
}

// 通过id获取定投活动详情
export function autoInvestDetail(data) {
	return request({
		url: '/earn/autoInvest/activity/Detail',
		method: 'POST',
		data: data
	})
}

// 创建定投计划
export function autoInvestAdd(data) {
	return request({
		url: '/earn/autoInvest/plan/add',
		method: 'POST',
		data: data
	})
}


// 定期资产信息
export function lockedAssets(data) {
	return request({
		url: '/earn/locked/assets/info',
		method: 'POST',
		data: data
	})
}

// 定期资产信息
export function goingOrder(data) {
	return request({
		url: '/earn/locked/going/order',
		method: 'POST',
		data: data
	})
}

// 获取已完成定期活动订单
export function doneOrder(data) {
	return request({
		url: '/earn/locked/done/order',
		method: 'POST',
		data: data
	})
}


// 获取计划列表
export function autoInvestPlanList(data) {
	return request({
		url: '/earn/autoInvest/plan/list',
		method: 'POST',
		data: data
	})
}

// 更新定投计划
export function autoInvestUpdatePlan(data) {
	return request({
		url: '/earn/autoInvest/plan/updatePlan',
		method: 'POST',
		data: data
	})
}

// 删除定投计划
export function autoInvestdelPlan(data) {
	return request({
		url: '/earn/autoInvest/plan/delPlan',
		method: 'POST',
		data: data
	})
}

// 获取定投订单
export function autoInvestOrderList(data) {
	return request({
		url: '/earn/autoInvest/order/list',
		method: 'POST',
		data: data
	})
}