<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<OrderNav></OrderNav>
			</div>
			<div class="admin-col whole_000 whole_fff flex_bd">
				<div class="order_tab">
					<!-- <div class="or_inpp">
						<el-input v-model="input" :placeholder="$t('zbplaceholder')" prefix-icon="el-icon-search" class="order_input">
						</el-input>
					</div> -->
					<el-tabs v-model="activeName"  @tab-click="handleClick">
						<el-tab-pane :label="$t('zbtab1')" name="first">
							<div class="order-table">
								<el-table :data="tableData" style="width: 100%">
									<el-table-column prop="coinUnit" :label="$t('wtth3')">
									</el-table-column>
									<el-table-column prop="cycle" :label="$t('zbth1')">
										<template slot-scope="scope">
											<span v-if="scope.row.cycle == 0">{{$t('redTxt7')}}</span>
											<span v-if="scope.row.cycle == 1">{{$t('redTxt8')}}</span>
											<span v-if="scope.row.cycle == 2">{{$t('redTxt9')}}</span>
										</template>
									</el-table-column>
									<el-table-column prop="status" :label="$t('zbth2')">
										<template slot-scope="scope">
											<div>
												<el-switch v-model="scope.row.status" :active-value="0"
													:inactive-value="1" inactive-color="#E6E7EA" active-color="#f0ac19">
												</el-switch>
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="cumulativeAmount" :label="$t('zbth3')">
										<template slot-scope="scope">
											<span>
												{{scope.row.cumulativeAmount}} {{scope.row.baseUnit}}
											</span>
										</template>
									</el-table-column>
									<el-table-column prop="cumulativeBuyAmount" :label="$t('zbth4')">
										<template slot-scope="scope">
											<span>
												{{scope.row.cumulativeBuyAmount}} {{scope.row.coinUnit}}
											</span>
										</template>
									</el-table-column>
									<el-table-column prop="averagePrice" :label="$t('zbth5')">
										<template slot-scope="scope">
											<span>
												{{scope.row.averagePrice}} {{scope.row.baseUnit}}
											</span>
										</template>
									</el-table-column>
									<el-table-column prop="profitLoss" :label="$t('zbth6')">
									</el-table-column>
									<el-table-column prop="roi" label="ROI">
									</el-table-column>
									<el-table-column prop="nextTime" :label="$t('zbth7')">
									</el-table-column>
									<el-table-column prop="createTime" :label="$t('zbth8')">
									</el-table-column>
									<el-table-column :label="$t('th5')">
										<template slot-scope="scope">
											<div class="order-btns">
												<el-button type="primary" @click="handleEdit(scope.row)">
													{{$t('acbtn3')}}
												</el-button>
												<el-button type="primary" @click="handleDelete(scope.row.id)">
													{{$t('delete')}}
												</el-button>
											</div>
										</template>
									</el-table-column>
									<template slot="empty" class="exte_empty">
										<img src="../../assets/photo/exte1.png" alt="">
										<p>{{$t('czEmpty')}}</p>
									</template>
								</el-table>
								<div class="exte-box">
									<el-pagination @current-change="handleCurrentChange"
										:current-page.sync="currentPage" layout="prev, pager, next" :total="total"
										:background="true"></el-pagination>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane :label="$t('zbtab2')" name="second">
							<div class="order-table">
								<el-table :data="tableData2" style="width: 100%">
									<el-table-column prop="investTime" :label="$t('atth1')">
									</el-table-column>
									<el-table-column prop="coinUnit" :label="$t('wtth3')">
									</el-table-column>
									<el-table-column prop="baseNum" :label="$t('atth2')">
										<template slot-scope="scope">
											<span>
												{{scope.row.baseNum}} {{scope.row.baseUnit}}
											</span>
										</template>
									</el-table-column>
									<el-table-column prop="num" :label="$t('zbth4')">
										<template slot-scope="scope">
											<span>
												{{scope.row.num}} {{scope.row.coinUnit}}
											</span>
										</template>
									</el-table-column>
									<el-table-column prop="fee" :label="$t('atth3')">
										<template slot-scope="scope">
											<span>
												{{scope.row.fee}} {{scope.row.coinUnit}}
											</span>
										</template>
									</el-table-column>
									<el-table-column prop="status" :label="$t('wtth8')">
										<template slot-scope="scope">
											<span v-if="scope.row.status == 0">
												{{$t('success')}}
											</span>
										</template>
									</el-table-column>
									<template slot="empty" class="exte_empty">
										<img src="../../assets/photo/exte1.png" alt="">
										<p>{{$t('czEmpty')}}</p>
									</template>
								</el-table>
								<div class="exte-box">
									<el-pagination @current-change="handleCurrentChange2"
										:current-page.sync="currentPage2" layout="prev, pager, next" :total="total2"
										:background="true"></el-pagination>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
					<el-dialog :visible.sync="dialogVisible" width="490px" :show-close="false"
						custom-class="custom-dialog">
						<div slot="title" class="dialog-title">
							{{$t('attit')}}
						</div>
						<div class="dialog-content">
							<el-form ref="form" :model="form">
								<div class="bi-row">
									<div class="bi-col">
										<div class="bi-label">{{$t('attxt')}}</div>
										<div class="bi-value">
											<el-input v-model="form.amount"></el-input>
										</div>
									</div>
								</div>
								<div class="bi-panel">
									<div class="bi-hd">{{$t('attxt2')}}</div>
									<div class="bi-bd">
										<div class="bi-radio">
											<el-radio-group v-model="form.cycle">
												<el-radio-button label="0">{{$t('redTxt7')}}</el-radio-button>
												<el-radio-button label="1">{{$t('redTxt8')}}</el-radio-button>
												<el-radio-button label="2">{{$t('redTxt9')}}</el-radio-button>
											</el-radio-group>
										</div>
									</div>
								</div>
								<div class="bi-panel">
									<div class="bi-hd">{{$t('labStart')}}</div>
									<div class="bi-bd">
										<div class="bi-date">
											<div class="bi-date__inner">
												<el-time-picker v-model="form.startTime"
													:picker-options="{selectableRange: '00:00:00 - 23:45:45'}"
													:placeholder="$t('lctxt4')" value-format="HH:mm:ss" prefix-icon="">
												</el-time-picker>
											</div>
										</div>
									</div>
								</div>
							</el-form>
						</div>
						<div slot="footer" class="dialog-footer">
							<el-row :gutter="14">
								<el-col :span="12">
									<el-button @click="dialogVisible = false">{{$t('cancel')}}</el-button>
								</el-col>
								<el-col :span="12">
									<el-button type="primary" @click="autoInvestConfrim">{{$t('confirm')}}</el-button>
								</el-col>
							</el-row>
						</div>
					</el-dialog>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		autoInvestPlanList,
		autoInvestUpdatePlan,
		autoInvestdelPlan,
		autoInvestOrderList
	} from '@/api/api/earn'
	import OrderNav from '@/components/OrderNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			OrderNav,
			Foot
		},
		data() {
			return {
				input: '',
				activeName: 'first',
				tableData: [],
				tableData2: [],
				currentPage: 1,
				pageSize: 10,
				total: 10,
				currentPage2: 1,
				pageSize2: 10,
				total2: 10,
				dialogVisible: false,
				form: {}
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				autoInvestPlanList({
					pageNo: this.currentPage,
					pageSize: this.pageSize,
				}).then(res => {
					if (res.code == 0) {
						this.tableData = res.data.content
						this.total = res.data.totalElements
						this.pageSize = res.data.size
					}
				})
			},
			getList2() {
				autoInvestOrderList({
					pageNo: this.currentPage2,
					pageSize: this.pageSize2,
				}).then(res => {
					if (res.code == 0) {
						this.tableData2 = res.data.content
						this.total2 = res.data.totalElements
						this.pageSize2 = res.data.size
					}
				})
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			},
			handleCurrentChange2(val) {
				this.currentPage2 = val
				this.getList2()
			},
			handleEdit(row) {
				var obj = JSON.parse(JSON.stringify(row))
				this.form = obj
				this.dialogVisible = true
			},
			handleClick(tab) {
				if (tab.name == "second") {
					this.getList2()
				}
				if (tab.name == "first") {
					this.getList()
				}
			},
			autoInvestConfrim() {
				autoInvestUpdatePlan({
					amount: this.form.amount,
					cycle: this.form.cycle,
					id: this.form.id,
					startTime: this.form.startTime
				}).then(res => {
					if (res.code == 0) {
						this.$message({
							message: res.message,
							type: 'success'
						});
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message({
							message: res.message,
							type: 'error'
						});
					}
				})
			},
			handleDelete(id) {
				autoInvestdelPlan({
					id: id
				}).then(res => {
					if (res.code == 0) {
						this.$message({
							message: res.message,
							type: 'success'
						})
						this.getList()
					}
				})
			}
		}
	}
</script>
