<template>
	<div class="acc_navbar">
		<div class="aside-bar">
			<el-menu :default-active="activeIndex" ref="test" :unique-opened='true' :default-openeds="defaultOpeneds"
				class="aside-menu-nav" :router="true" @select="handleSelect">
				<el-submenu index="1">
					<template slot="title">
						<img src="../assets/photo/aside3.png" alt="" class="ico"><span>{{$t('meun11_1')}}</span>
					</template>
					<el-menu-item index="1-1" :route="{ path: '/spotOrder' }">{{$t('wTxt2')}}</el-menu-item>
					<el-menu-item index="1-2" :route="{ path: '/orderHistory' }">{{$t('hisTxt')}}</el-menu-item>
				</el-submenu>
				<!-- <el-submenu index="2">
					<template slot="title">
						<img src="../assets/photo/aside1.png" alt="" class="ico"><span>{{$t('ormenu1')}}</span>
					</template>
					<el-menu-item index="2-1" :route="{ path: '/ad' }">{{$t('adTxt')}}</el-menu-item>
					<el-menu-item index="2-2" :route="{ path: '/orderMy' }">{{$t('ormenu2')}}</el-menu-item>
				</el-submenu> -->
				<el-submenu index="3">
					<template slot="title">
						<img src="../assets/photo/aside2.png" alt="" class="ico"><span>{{$t('meun11_3')}}</span>
					</template>
					<el-menu-item index="3-1" :route="{ path: '/earnOrder' }">{{$t('ormenu3')}}</el-menu-item>
					<el-menu-item index="3-2" :route="{ path: '/orderThrow' }">{{$t('reTxt6')}}</el-menu-item>
				</el-submenu>
				<!-- <el-menu-item index="4" :route="{ path: '/convertHistory' }">
					<img src="../assets/photo/aside4.png" alt="" class="ico"><span>{{$t('meun11_4')}}</span>
				</el-menu-item>
				<el-submenu index="5">
					<template slot="title">
						<img src="../assets/photo/aside5.png" alt="" class="ico"><span>{{$t('meun11_5')}}</span>
					</template>
					<el-menu-item index="5-1" :route="{ path: '/innovation/myorders' }">{{$t('cxnav1')}}</el-menu-item>
					<el-menu-item index="5-2" :route="{ path: '/innovation/myminings' }">{{$t('cxnav2')}}</el-menu-item>
					<el-menu-item index="5-3" :route="{ path: '/innovation/mylocked' }">{{$t('cxnav3')}}</el-menu-item>
				</el-submenu> -->
			</el-menu>
		</div>
		<i class="iconfont icon-menu1" @click="drawer = true" type="primary"></i>
		<el-drawer :visible.sync="drawer" :direction="direction" size="240px">
			<div class="acc_bar">
				<el-menu :default-active="activeIndex" ref="test" :unique-opened='true' :default-openeds="defaultOpeneds"
					class="aside-menu-nav" :router="true" @select="handleSelect">
					<el-submenu index="1">
						<template slot="title">
							<img src="../assets/photo/aside3.png" alt="" class="ico"><span>{{$t('meun11_1')}}</span>
						</template>
						<el-menu-item index="1-1" :route="{ path: '/spotOrder' }">{{$t('wTxt2')}}</el-menu-item>
						<el-menu-item index="1-2" :route="{ path: '/orderHistory' }">{{$t('hisTxt')}}</el-menu-item>
					</el-submenu>
					<el-submenu index="2">
						<template slot="title">
							<img src="../assets/photo/aside1.png" alt="" class="ico"><span>{{$t('ormenu1')}}</span>
						</template>
						<el-menu-item index="2-1" :route="{ path: '/ad' }">{{$t('adTxt')}}</el-menu-item>
						<el-menu-item index="2-2" :route="{ path: '/orderMy' }">{{$t('ormenu2')}}</el-menu-item>
					</el-submenu>
					<el-submenu index="3">
						<template slot="title">
							<img src="../assets/photo/aside2.png" alt="" class="ico"><span>{{$t('meun11_3')}}</span>
						</template>
						<el-menu-item index="3-1" :route="{ path: '/earnOrder' }">{{$t('ormenu3')}}</el-menu-item>
						<el-menu-item index="3-2" :route="{ path: '/orderThrow' }">{{$t('reTxt6')}}</el-menu-item>
					</el-submenu>
					<el-menu-item index="4" :route="{ path: '/convertHistory' }">
						<img src="../assets/photo/aside4.png" alt="" class="ico"><span>{{$t('meun11_4')}}</span>
					</el-menu-item>
					<el-submenu index="5">
						<template slot="title">
							<img src="../assets/photo/aside5.png" alt="" class="ico"><span>{{$t('meun11_5')}}</span>
						</template>
						<el-menu-item index="5-1" :route="{ path: '/innovation/myorders' }">{{$t('cxnav1')}}</el-menu-item>
						<el-menu-item index="5-2" :route="{ path: '/innovation/myminings' }">{{$t('cxnav2')}}</el-menu-item>
						<el-menu-item index="5-3" :route="{ path: '/innovation/mylocked' }">{{$t('cxnav3')}}</el-menu-item>
					</el-submenu>
				</el-menu>
			</div>
		</el-drawer>
	</div>

</template>

<script>
	export default {
		name: 'orderCurrent',
		data() {
			return {
				drawer: false,
				direction: 'ltr',
				activeIndex: '1-1',
				isLogin: false,
				defaultOpeneds: ['1'],
				routeArr: {
					'/spotOrder': '1-1',
					'/orderHistory': '1-2',
					'/ad': '2-1',
					'/orderMy': '2-2',
					'/earnOrder': '3-1',
					'/orderThrow': '3-2',
					'/convertHistory': '4',
					'/innovation/myorders': '5-1',
					'/innovation/myminings': '5-2',
					'/innovation/mylocked': '5-3',
				}
			};
		},
		created() {
			this.init()
			const path = this.$route.path
			this.heightLightMenu(path)
		},
		mounted() {
			// this.$nextTick(function() {
			// 	this.$refs.test.open()
			// 	this.$refs.test.updateActiveName()
			// })
		},
		computed: {

		},
		methods: {
			init() {
				let isLogin = this.$store.state.isLogin
				if (isLogin == false) {
					this.$router.push('/')
				}
			},
			handleSelect(key, keyPath) {
				this.defaultOpeneds = [keyPath[0]]
				this.activeIndex = key
				this.link(this.activeIndex)
			},
			link(code) {
				switch (code) {
					default:
						this.$router.push('/spotOrder')
						break
					case '1-2':
						this.$router.push('/orderHistory')
						break
					case '2-1':
						this.$router.push('/ad')
						break
					case '2-2':
						this.$router.push('/orderMy')
						break
					case '3-1':
						this.$router.push('/earnOrder')
						break
					case '3-2':
						this.$router.push('/orderThrow')
						break
					case '5-1':
						this.$router.push('/innovation/myminings')
						break
					case '5-2':
						this.$router.push('/uc/innovation/myminings')
						break
					case '5-3':
						this.$router.push('/innovation/mylocked')
						break
				}
			},
			heightLightMenu(path) {
				let acName = this.routeArr[path] || '1-1',
					opName = acName.split('-')[0]
				this.defaultOpeneds = [opName]
				this.activeIndex = acName
				// this.$nextTick(function() {
				// 	this.$refs.test.open()
				// 	this.$refs.test.updateActiveName()
				// })
			},
		},
		watch: {
			$route(to) {
				this.heightLightMenu(to.path)
			}
		}
	}
</script>
<style>
</style>
